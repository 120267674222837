<template>
    <div>
        <div class="d-flex justify-content-center">
            <b-card class="text-center m-1">
                <div class="m-4">
                    <p>
                        <feather-icon
                            icon="DownloadIcon"
                            size="80"
                            class="text-primary"
                        />
                    </p>
                    <h2>YOUR FILE IS BEING PROCESSED</h2>
                    <p>
                        Waiting time depends on your file size.<br />If the
                        waiting time is too long, please contact us!
                    </p>
                </div>
                <a :href="dl_link" target="_blank"
                    ><b-button
                        :variant="variant_btn"
                        block
                        class="mb-1"
                        :disabled="disabled_btn"
                    >
                        <b-spinner
                            small
                            type="grow"
                            class="mr-50"
                            v-if="text_btn == 'Loading ...'"
                        />{{ text_btn }}</b-button
                    ></a
                >
            </b-card>
        </div>
    </div>
</template>

<script>
import { BCard, BButton } from "bootstrap-vue";
import { BSpinner } from "bootstrap-vue";
export default {
    components: {
        BCard,
        BButton,
        BSpinner,
    },
    data() {
        return {
            text_btn: "Loading ...",
            disabled_btn: true,
            dl_link: null,
            variant_btn: "primary",
            timer: null,
        };
    },
    mounted: function () {
        this.timer = setInterval(() => {
            this.linkDownload();
        }, 10000);
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
    methods: {
        linkDownload() {
            this.$store
                .dispatch("linkDownload", this.$route.params.hash)
                .then((res) => {
                    if (res.data.success == false) {
                        this.text_btn = res.data.message;
                        this.variant_btn = "danger";
                    } else if (res.data.success == true) {
                        clearInterval(this.timer);
                        this.text_btn = "Click here to Download";
                        this.dl_link = res.data.link;
                        this.disabled_btn = false;
                    }
                })
                .catch((err) => console.log(err));
        },
    },
};
</script>

<style></style>
